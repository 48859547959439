<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" />
    <v-container fluid>
      <v-row class="ma-1">
        <div
          style="
            background-color: #a6cc39;
            width: 270px;
            margin-top: -4px;
            border-radius: 10px;
            height: 45px;
          "
          class="text-center elevation-2"
        >
          <p style="color: #ffffff; font-size: 27px; margin-top: 2px">
            {{ $t("courselistreport") }}
          </p>
        </div>
      </v-row>
      <v-row justify="space-between" class="ma-1">
        <div style="width: 850px; margin-top: 14px">
          <v-row>
            <v-col>
              <div class="d-flex from-date">
                <label
                  for="from"
                  style="
                    font-size: 20px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("from") }}</label
                >
                <v-menu
                  v-model="frommenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromdate"
                      class="ml-3"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromdate"
                    @input="startDateDis"
                    :allowed-dates="allowedDates"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col>
              <div class="d-flex to-date">
                <label
                  for="to"
                  style="
                    font-size: 20px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("to") }}</label
                >
                <v-menu
                  v-model="tomenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="ml-3"
                      v-model="todate"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="todate"
                    @input="tomenu = false"
                    :allowed-dates="allowedDatesEnd"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col>
              <div class="d-flex" style="width: 350px">
                <label
                  for="coursename"
                  style="
                    font-size: 20px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                    width: 180px;
                  "
                  >Course Name</label
                >
                <v-combobox
                  hide-selected
                  readonly
                  :items="courseNameList"
                  outlined
                  dense
                  hide-details="auto"
                ></v-combobox>
              </div>
            </v-col>
          </v-row>
        </div>
        <div style="width: 150px">
          <v-row>
            <v-col>
              <v-text-field
                :placeholder="$t('search')"
                v-model="search"
                append-icon="mdi-magnify"
              ></v-text-field> </v-col
          ></v-row>
        </div>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        class="assingnment-course"
        :search="search"
        @page-count="pageCount = $event"
        :page.sync="page"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td class="text-xs-start">
              {{ item.no }}
            </td>
            <td class="text-xs-center">
              <p class="mt-3">
                {{ item.courseName }}
              </p>
            </td>
            <td class="text-xs-start">
              <p class="mt-3" v-for="(subItem, i) in item.videoList" :key="i">
                {{ i + 1 + "." }}{{ subItem.videoName }}
              </p>
            </td>
            <td class="text-xs-start">
              <p class="mt-3" v-for="(subItem, i) in item.videoList" :key="i">
                {{ subItem.size }}
              </p>
            </td>

            <td class="text-xs-start">
              <p class="mt-3" v-for="(subItem, i) in item.videoList" :key="i">
                {{ subItem.duration }}
              </p>
            </td>
            <td class="text-xs-center">10</td>
            <td class="text-xs-start">
              {{ item.startDateTime }}
            </td>
            <td class="text-xs-start">{{ item.endDateTime }}</td>
            <td class="text-xs-start" style="color: #6cc8a0">Active</td>
            <td>1</td>
            <td class="text-xs-start">Active</td>
            <p
              class="mt-3 text-decoration-underline"
              style="color: #a6cc39; cursor: pointer"
            >
              {{ item.courseName }}
            </p>
          </tr>
        </template>
      </v-data-table>
      <v-row justify="end">
        <div class="ma-5 d-flex">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                width="150"
                v-bind="attrs"
                v-on="on"
                style="border-color: 1px solid #e9e9f0; opacity: 1"
                class="text-capitalize mt-1"
                outlined
              >
                {{ itemsPerPage }} / page
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in paginationCount"
                :key="index"
                @click="itemsPerPage = item.title"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-row>
    </v-container>

    <!-- NewAssignment -->
    <v-dialog width="1229" v-model="openAddDialog" persistent>
      <v-card>
        <v-card-title
          style="font-size: 40px; height: 80px"
          class="secondary white--text"
          >{{ $t("addcourse") }}</v-card-title
        ><!-- addassignmentcourse -->
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="8">
                <label for="coursename" class="font-style"
                  >Course Name <span style="color: red">*</span></label
                >
                <v-text-field
                  v-model="$store.state.assignmentUser.assignmentCourseName"
                  :rules="courseRules"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label for="startdatetime" class="font-style"
                  >Start Date Time</label
                >
                <v-menu
                  v-model="startdatemenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="$store.state.assignmentUser.startdate"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="$store.state.assignmentUser.startdate"
                    @input="newstartDateDis"
                    :allowed-dates="newallowedDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div class="d-inline">
                  <label for="enddatetime" class="font-style"
                    >End Date Time</label
                  >
                  <v-menu
                    v-model="enddatemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="$store.state.assignmentUser.enddate"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="$store.state.assignmentUser.enddate"
                      @input="enddatemenu = false"
                      :allowed-dates="newendallowedDates"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox
                  v-model="$store.state.assignmentUser.mushwatchOrder"
                  label="Must Watch In Order"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox
                  v-model="$store.state.assignmentUser.prohiForward"
                  label="Prohibit Forward"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="font-style">Video List</p>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <div class="text-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="d-flex"
                        style="font-size: 20px; padding-top: 15px; float: right"
                        color="#6CC8A0"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        <p class="text-capitalize">
                          Upload<v-icon style="margin-top: -4px"
                            >mdi-menu-down</v-icon
                          >
                        </p>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title
                          style="
                            font-size: 20px;
                            color: #4d4f5c;
                            cursor: pointer;
                          "
                          @change="getVideo"
                          @click="
                            $router.push('/home/library'),
                              addassignmentCourse(),
                              $store.commit('changeLibraryAction', true)
                          "
                        >
                          From Library</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title
                          style="
                            font-size: 20px;
                            color: #4d4f5c;
                            cursor: pointer;
                          "
                        >
                          <input
                            type="file"
                            accept="video/mp4"
                            class="d-none"
                            ref="video"
                            @change="getVideo"
                          />
                          <p @click="$refs.video.click()">
                            From PC
                          </p></v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <div class="mt-4">
              <v-data-table
                :headers="libraryandpcheaders"
                :items="$store.state.assignmentUser.pcVideoData"
                hide-default-footer
                class="assign-tablepc"
                :search="search"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-xs-start" style="width: 90px">
                      {{ item.part }}
                    </td>
                    <td class="text-xs-center">
                      <p
                        style="
                          width: 250px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ item.videoName }}
                      </p>
                    </td>
                    <td class="text-xs-start">
                      <p
                        style="
                          width: 250px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ item.videoFile }}
                      </p>
                    </td>
                    <td class="text-xs-center">
                      {{ item.size }}
                    </td>

                    <td class="text-xs-center">
                      {{ item.duration }}
                    </td>

                    <td>
                      <div class="d-flex">
                        <v-btn
                          color="#6CC8A0"
                          text
                          style="font-size: 20px"
                          class="text-capitalize"
                          @click="editPcandLibrarydialog = true"
                          >{{ $t("edit") }}</v-btn
                        >
                        <!-- editformlibraryandpc(item), -->
                        <v-btn
                          color="error"
                          text
                          style="font-size: 20px"
                          class="text-capitalize"
                          @click="checkVideolistDelete(item.index)"
                          >{{ $t("delete") }}</v-btn
                        >
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox
                  v-model="$store.state.assignmentUser.allUserList"
                  @click="saveAllUser()"
                  label="All User"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <p class="font-style">User</p>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  color="#6CC8A0"
                  style="font-size: 20px; margin-top: 13px; float: right"
                  class="text-capitalize white--text"
                  @click="$router.push('/home/assignment-course/user-list')"
                  >Add User</v-btn
                >
              </v-col>
            </v-row>
            <div class="mt-4">
              <v-data-table
                :headers="userheaders"
                :items="selectedList"
                hide-default-footer
                class="assign-table"
                :search="search"
              >
                <template v-slot:item.actions="{ item }">
                  <div>
                    <v-btn
                      color="error"
                      text
                      style="font-size: 20px"
                      class="text-capitalize"
                      @click="checkUserDelete(item.index)"
                      >{{ $t("delete") }}</v-btn
                    >
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pb-5 mt-3">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize font-style"
            text
            @click="cancelAssignmentCourse()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize font-style white--text"
            color="#6CC8A0"
            style="width: 139px"
            @click="saveAssignmentCourse()"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Editassign   -->
    <v-dialog width="1229" v-model="editassignmentcoursedialog" persistent>
      <v-card>
        <v-card-title
          style="font-size: 40px; height: 80px"
          class="secondary white--text"
          >Assignment Course</v-card-title
        >
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="pt-4">
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  color="#6CC8A0"
                  style="font-size: 20px; float: right; width: 130px"
                  class="text-capitalize white--text"
                  @click="editassignmentcourse()"
                  >Edit</v-btn
                >
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col cols="8">
                <label for="coursename" class="font-style"
                  >Course Name <span style="color: red">*</span></label
                >
                <v-text-field
                  v-model="courseName"
                  :rules="courseRules"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label for="startdatetime" class="font-style"
                  >Start Date Time</label
                >
                <v-menu
                  v-model="editstartdatemenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editstartdate"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editstartdate"
                    @input="editstartDateDis"
                    :allowed-dates="startallowedDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <div class="d-inline">
                  <label for="enddatetime" class="font-style"
                    >End Date Time</label
                  >
                  <v-menu
                    v-model="editenddatemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editenddate"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editenddate"
                      @input="editenddatemenu = false"
                      :allowed-dates="endallowedDates"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox label="Must Watch In Order"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox label="Prohibit Forward"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="font-style">Video List</p>
                {{ selectedvideoList }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <div class="text-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="d-flex"
                        style="font-size: 20px; padding-top: 15px; float: right"
                        color="#6CC8A0"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        <p class="text-capitalize">
                          Upload<v-icon style="margin-top: -4px"
                            >mdi-menu-down</v-icon
                          >
                        </p>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title
                          style="
                            font-size: 20px;
                            color: #4d4f5c;
                            cursor: pointer;
                          "
                          @click="
                            $router.push('/home/library'),
                              $store.commit('changeLibraryAction', true),
                              getLibraryVideo()
                          "
                        >
                          From Library</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title
                          style="
                            font-size: 20px;
                            color: #4d4f5c;
                            cursor: pointer;
                          "
                        >
                          <input
                            type="file"
                            accept="video/mp4"
                            class="d-none"
                            ref="video"
                            @change="getVideo"
                          />
                          <p @click="$refs.video.click()">
                            From PC
                          </p></v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <div class="mt-4">
              <v-data-table
                :headers="libraryandpcheaders"
                :items="items"
                hide-default-footer
                class="assign-table"
                :search="search"
              >
                <template v-slot:item.pic="{ item }">
                  <div class="mt-1 mb-1">
                    <v-img
                      :src="item.pic"
                      style="width: 172px; height: 95px"
                    ></v-img>
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex">
                    <v-btn
                      color="#6CC8A0"
                      text
                      style="font-size: 20px"
                      class="text-capitalize"
                      @click="editBanner(item)"
                      >{{ $t("edit") }}</v-btn
                    >
                    <v-btn
                      color="error"
                      text
                      style="font-size: 20px"
                      class="text-capitalize"
                      @click="checkAssignmentDelete(item)"
                      >{{ $t("delete") }}</v-btn
                    >
                  </div>
                </template>
              </v-data-table>
            </div>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-checkbox label="All User"></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <p class="font-style">User</p>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  color="#6CC8A0"
                  style="font-size: 20px; margin-top: 13px; float: right"
                  class="text-capitalize white--text"
                  >Add User</v-btn
                >
              </v-col>
            </v-row>
            <div class="mt-4">
              <v-data-table
                :headers="userheaders"
                :items="selectedList"
                hide-default-footer
                class="assign-table"
                :search="search"
              >
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex">
                    <v-btn
                      color="#6CC8A0"
                      text
                      style="font-size: 20px"
                      class="text-capitalize"
                      @click="editBanner(item)"
                      >{{ $t("edit") }}</v-btn
                    >
                    <v-btn
                      color="error"
                      text
                      style="font-size: 20px"
                      class="text-capitalize"
                      @click="checkBannerDelete(item)"
                      >{{ $t("delete") }}</v-btn
                    >
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pb-5 mt-3">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize font-style"
            text
            @click="cancelAssignmentCourse()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize font-style white--text"
            color="#6CC8A0"
            style="width: 139px"
            @click="saveBanner()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete -->
    <v-dialog v-model="deleteassignmentcourse" width="587">
      <v-card>
        <v-card-title
          style="font-size: 40px; color: #ffffff; height: 84px"
          class="secondary justify-center"
        >
          <p>{{ $t("deleteconfirm") }}</p>
        </v-card-title>

        <v-card-text>
          <p
            class="mt-5"
            style="font-size: 20px; color: #4d4f5c; text-align: center"
          >
            {{ $t("suredelete") }} "User #{{ editData.id }}" ?
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            style="font-size: 20px"
            width="100"
            text
            @click="deleteassignmentcourse = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize"
            width="100"
            style="font-size: 20px"
            color="error"
            @click="deleteData()"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit pc and library -->
    <v-dialog
      v-model="editPcandLibrarydialog"
      persistent
      width="1220"
      scrollable
    >
      <v-card>
        <v-card-title
          style="font-size: 40px"
          class="secondary white--text pb-4"
          >{{ $t("editvideo") }}</v-card-title
        >
        <v-card-text style="height: 600px">
          <v-form ref="form" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12" xs="12" sm="12" md="6" lg="8">
                <v-row>
                  <v-col cols="12">
                    <label for="title" class="font-style">
                      {{ $t("title") }}
                      <span style="color: red">*</span>
                    </label>
                    <v-text-field
                      :rules="titleRules"
                      outlined
                      dense
                      hide-details="auto"
                      id="title"
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label for="description" class="font-style">{{
                      $t("description")
                    }}</label>
                    <v-text-field
                      outlined
                      dense
                      hide-details="auto"
                      id="description"
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    class="loading-circle"
                    color="green"
                  ></v-progress-circular>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <label for="startTime" class="font-style">
                      {{ $t("startdate") }}
                    </label>
                    <v-menu
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="rounded-lg"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          id="startDate"
                          append-icon="mdi-calendar"
                          v-model="startContentDate"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :allowed-dates="allowedStartDates"
                        color="secondary"
                        v-model="startContentDate"
                        @input="startDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <label for="endDate" class="font-style">
                      {{ $t("enddate") }}
                    </label>
                    <v-menu
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="rounded-lg"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          id="endDate"
                          append-icon="mdi-calendar"
                          v-model="endContentDate"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :allowed-dates="allowedEndDates"
                        color="secondary"
                        v-model="endContentDate"
                        @input="endDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="4">
                <p
                  class="text-center font-style"
                  :style="fileError ? { color: 'red' } : {}"
                >
                  {{ $t("picturebackground") }}
                  <span style="color: red">*</span>
                </p>
                <v-img
                  width="150"
                  class="mx-auto"
                  @click="$refs.file.click()"
                ></v-img>
                <p class="text-center mt-3 font-style" style="color: red">
                  {{ $t("recommended") }} PNG, JPG : 800 x600
                  {{ $t("pixels") }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="editvideoHeaders"
                  :items="editvideoList"
                  hide-default-footer
                >
                  <template v-slot:item.part="{ item }"
                    >Part {{ item.part }}</template
                  >
                  <template v-slot:item.permission="{ item }">
                    <v-btn
                      :color="
                        item.part == showedPermissionIndex
                          ? '#DD9831'
                          : 'primary'
                      "
                      class="text-capitalize"
                      text
                      style="font-size: 20px"
                      @click="checkPermission(item)"
                      >view permission</v-btn
                    >
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      color="error"
                      style="font-size: 20px"
                      class="text-capitalize"
                      text
                      @click="deleteVideoList(item.part)"
                      >Delete</v-btn
                    >
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12">
                <span class="font-style">{{ $t("assignpartlist") }}</span>
                <v-data-table
                  class="mt-3"
                  :items="editvideoselectedList"
                  :headers="editvideoselectedHeader"
                  hide-default-footer
                >
                  <template v-slot:item.permission="{ item }">
                    <v-checkbox
                      color="secondary"
                      v-model="item.permission"
                    ></v-checkbox>
                  </template>
                  <template v-slot:item.request="{ item }">
                    <v-checkbox
                      color="secondary"
                      v-model="item.request"
                    ></v-checkbox>
                  </template>
                  <template v-slot:item.times="{ item }">
                    <v-text-field
                      v-if="item.request"
                      v-model.number="item.times"
                      type="number"
                      outlined
                      dense
                      :rules="timesRules"
                      hide-details="auto"
                      min="0"
                      style="width: 120px"
                    ></v-text-field>
                  </template>
                  <template v-slot:item.perDay="{ item }">
                    <div v-if="item.request">
                      <v-menu
                        ref="menu"
                        v-model="item.menu"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-on="on"
                            v-bind="attrs"
                            v-model="item.selectedDate"
                            append-icon="mdi-calendar-month"
                            solo
                            dense
                            readonly
                            hide-details="auto"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :allowed-dates="allowedStartDates"
                          multiple
                          v-model="item.perDay"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            class="font-style"
                            @click="item.menu = false"
                            >{{ $t("cancel") }}</v-btn
                          >
                          <v-btn
                            text
                            class="font-style"
                            color="primary"
                            @click="
                              $refs.menu.save(item.perDay),
                                (item.menu = false),
                                (item.selectedDate = checkStatus(item.perDay))
                            "
                            >{{ $t("ok") }}</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize font-style"
            text
            @click="editPcandLibrarydialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="text-capitalize font-style white--text"
            color="#6CC8A0"
            @click="submit()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Success -->
    <v-dialog v-model="successDialog" width="587">
      <v-card>
        <v-card-title
          style="font-size: 40px; color: #ffffff; height: 84px"
          class="secondary justify-center"
          >{{ message }}</v-card-title
        >

        <v-card-text>
          <p
            class="mt-5"
            style="font-size: 20px; color: #4d4f5c; text-align: center"
          >
            Deleting Assignment Course is successful.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            class="text-capitalize mx-auto white--text"
            width="100"
            color="#6CC8A0"
            style="font-size: 20px"
            @click="successDialog = false"
            >{{ $t("ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
    <SuccessDialog
      :show="addsuccessDialog"
      title="Success"
      text="Adding new Assignment is successful."
      @close="(addsuccessDialog = false), getAllAssignmentCourseData()"
    />
    <SuccessDialog
      :show="editsuccessDialog"
      title="Success"
      text="Updating Assignment is successful."
      @close="(editsuccessDialog = false), getAllAssignmentCourseData()"
    />
    <!-- cropimage -->
    <v-dialog
      v-model="dialogCrop"
      width="1000px"
      height="800px"
      overflow="hidden"
    >
      <v-card class="rounded-lg" overflow="hidden">
        <v-card-title
          class="white--text pb-4"
          style="background-color: #a6cc39; font-size: 40px"
          >Crop Image</v-card-title
        >
        <v-card-text
          class="pt-12"
          style="height: 550px; padding-left: 4rem; padding-right: 4rem"
        >
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
          ></vue-cropper>
        </v-card-text>
        <v-card-actions class="mt-3 pb-4" style="margin-right: 50px">
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize font-style" text @click="CancelCrop()">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="text-capitalize font-style"
            color="primary"
            @click="CropImage()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import { mapGetters } from "vuex";
import * as moment from "moment/moment";
export default {
  components: {
    VueCropper,
  },
  data: () => ({
    addUser: false,
    editPcandLibrarydialog: false,
    valid: true,
    prohiForward: false,
    allUserList: false,
    mushwatchOrder: false,
    videos: [],
    courseName: "",
    editassignmentcoursedialog: false,
    dialogCrop: false,
    courseNameList: ["haha"],
    option: {
      img: "",
      size: 1,
      full: false,
      outputType: "png",
      canMove: true,
      fixedBox: true,
      original: false,
      canMoveBox: false,
      autoCrop: true,
      autoCropWidth: 800,
      autoCropHeight: 450,
      centerBox: false,
      high: true,
    },
    assignmentCourse: false,
    permissionDialog: false,
    permissionMessage: "",
    addsuccessDialog: false,
    editsuccessDialog: false,
    endtime: null,
    time: null,
    starttime: null,
    endmenu: false,
    startmenu: false,
    editendtime: null,
    editstarttime: null,
    editendmenu: false,
    editstartmenu: false,
    breadcrumbs: [
      {
        image: "house.png",
      },
      {
        text: "assignmentcourse",
        href: "/home/assignment-course",
      },
      {
        text: "courselistreport",
      },
    ],
    editbannerdialog: false,
    deleteassignmentcourse: false,
    fromdate: new Date().toISOString().substr(0, 10),
    todate: new Date().toISOString().substr(0, 10),
    frommenu: false,
    tomenu: false,
    startdate: new Date().toISOString().substr(0, 10),
    enddate: new Date().toISOString().substr(0, 10),
    startdatemenu: false,
    enddatemenu: false,
    editstartdate: new Date().toISOString().substr(0, 10),
    editenddate: new Date().toISOString().substr(0, 10),
    editstartdatemenu: false,
    editenddatemenu: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    items: [],
    dialog: false,
    successDialog: false,
    message: "",
    search: null,
    editData: {},
    linkUrl: "",
    buttonName: "",
    bannerTitle: "",
    description: "",
    bannerID: 0,
    videoData: [],
    actualduration: "",
  }),
  mounted() {
    let year = new Date().getFullYear();
    let month =
      new Date().getMonth() + 1 <= 9
        ? "0" + parseInt(new Date().getMonth() + 1)
        : new Date().getMonth() + 1;
    let day =
      new Date().getDate() <= 9
        ? "0" + parseInt(new Date().getDate())
        : new Date().getDate();
    this.startDateDis(`${year}-${month}-${day}`);
    this.getAllAssignmentCourseData();
  },
  watch: {
    // file: function () {
    //   this.fileError = false;
    // },
    // selected(val) {
    //   if (val) {
    //     this.getSelectedVideo();
    //   }
    // },
    // checkVideoList(val) {
    //   if (val) {
    //     this.resVideoForShowInTable();
    //   }
    // },
  },
  computed: {
    ...mapGetters([
      "selectedvideoList",
      "selecteduserList",
      "assignmentCourse",
      "pcVideoData",
    ]),
    // checkVideoList() {
    //   return this.$store.getters.newassignmentDialog;
    // },
    editvideoselectedHeader() {
      return [
        {
          text: "NAME",
          value: "fullName",
        },
        {
          text: "PERMISSION",
          value: "permission",
        },
        {
          text: "REQUEST",
          value: "request",
        },
        {
          text: "REPEAT",
          value: "times",
        },
        {
          text: "DATE",
          value: "perDay",
        },
      ];
    },
    editvideoHeaders() {
      return [
        {
          text: "#",
          sortable: false,
          value: "part",
        },
        {
          text: "NAME",
          sortable: false,
          value: "songName",
        },
        {
          text: "TIME",
          sortable: false,
          value: "duration",
        },
        {
          text: "",
          sortable: false,
          value: "permission",
        },
        {
          text: "",
          sortable: false,
          value: "actions",
        },
      ];
    },
    openAddDialog: {
      get() {
        this.resVideoForShowInTable();
        return this.$store.getters.newassignmentDialog;
      },
      set() {
        this.$store.commit("closeAssignmentDialog");
      },
    },
    selectedList() {
      return this.$store.getters.selecteduserList;
    },
    selectedVDList() {
      return this.$store.getters.selectedvideoList;
    },
    // videos(){

    // },
    courseRules() {
      return [(v) => !!v || "Course Name is required"];
    },
    libraryandpcheaders() {
      return [
        {
          text: "#",
          value: "part",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "VIDEO NAME",
          align: "center",
          value: "videoName",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "VIDEO FILE NAME",
          align: "center",
          value: "videoFile",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "VIDEO FILE SIZE (MB)",
          align: "center",
          value: "size",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "TIME (MINUTES)",
          align: "center",
          value: "duration",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "ACTION",
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
        },
      ];
    },
    userheaders() {
      return [
        {
          text: "#",
          value: "no",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "USER ID",
          align: "center",
          value: "profileID",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "FULL NAME",
          align: "center",
          value: "fullName",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "EMAIL",
          align: "center",
          value: "email",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "PHONE",
          align: "center",
          value: "mobile",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "DEPARTMENT",
          align: "center",
          value: "department",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "ACTION",
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
        },
      ];
    },
    headers() {
      return [
        {
          text: "#",
          value: "no",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "COURSE NAME",
          align: "center",
          value: "courseName",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "IN PROGRESS USER",
          align: "center",
          value: "description",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "COMPLETE USER",
          align: "center",
          value: "startTime",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "TOTAL USER",
          align: "center",
          value: "endTime",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "VIDEO NAME",
          align: "center",
          value: "startTime",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "TIME(MINUTES)",
          align: "center",
          value: "endTime",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "COMPLETED VIEW(%)",
          align: "center",
          value: "startTime",
          class: "tableColor",
          sortable: false,
        },
        {
          text: "START DATE TIME",
          align: "center",
          value: "startDateTime",
          sortable: false,
          class: "tableColor",
        },
        {
          text: "END DATE TIME",
          align: "center",
          value: "endDateTime",
          sortable: false,
          class: "tableColor",
        },
        {
          text: "STATUS",
          align: "center",
          sortable: false,
          value: "endTime",
          class: "tableColor",
        },
        {
          text: "DETAIL",
          sortable: false,
          align: "center",
          value: "actions",
          class: "tableColor",
        },
      ];
    },
    todate: {
      get() {
        return this.fromdate;
      },
      set() {
        return this.fromdate;
      },
    },
    editenddate: {
      get() {
        return this.editstartdate;
      },
      set() {
        return this.editstartdate;
      },
    },
    enddate: {
      get() {
        return this.$store.state.assignmentUser.startdate;
      },
      set() {
        return this.$store.state.assignmentUser.startdate;
      },
    },
  },
  methods: {
    addassignmentCourse() {
      this.$store.commit(
        "saveAssignmentCourse",
        this.assignmentCourse.courseName
      );
    },
    async checkUserDelete(e) {
      this.selectedList.splice(
        this.selectedList.findIndex((item) => item.index === e),
        1
      );
    },
    async checkVideolistDelete(e) {
      //selectedVDList
      this.$store.state.assignmentUser.pcVideoData.splice(
        this.$store.state.assignmentUser.pcVideoData.findIndex(
          (item) => item.index === e
        ),
        1
      );
    },
    resVideoForShowInTable() {
      let res = [];
      for (const element of this.selectedVDList) {
        for (const j of element.partfileList) {
          res.push({
            id: 0,
            assignmentCourseID: 0,
            title: "",
            description: "",
            size: "5MB",
            allowDownload: true,
            pictureBackground: "",
            videoName: j.songName,
            videoFile: j.videoFile,
            duration: this.formatDuration(j.time) + "m",
          });
        }
      }
      this.$store.state.assignmentUser.pcVideoData = res.map((v, i) => ({
        ...v,
        part: parseInt(i + 1), //"Part " +
      }));
      return res;
    },
    async saveAllUser() {
      if (this.$store.state.assignmentUser.allUserList) {
        const res = await this.$axios.get(
          `${this.web_url}EmployeeEXT/GetEmployeeExtAll?id=` +
            localStorage.getItem("companyID")
        );
        this.$store.commit(
          "saveSelecteduserList",
          res.data.data.map((v, i) => ({
            ...v,
            no: i + 1,
          }))
        );
      } else {
        this.$store.commit("saveSelecteduserList");
      }
    },
    async getVideo(e) {
      this.soundLoading = true;
      const video = document.createElement("video");
      video.addEventListener("loadedmetadata", () => {
        this.actualduration = this.formatDuration(video.duration) + "m";
      });
      video.src = URL.createObjectURL(e.target.files[0]);
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      const res = await this.$axios.post(
        `${this.web_url}File/UploadFile`,
        formData
      );
      this.$store.state.assignmentUser.pcVideoData.push({
        id: 0,
        assignmentCourseID: 0,
        videoName: e.target.files[0].name,
        title: "",
        description: "",
        part: parseInt(this.$store.state.assignmentUser.pcVideoData.length + 1), //"Part " +
        size: parseFloat(e.target.files[0].size / 1000000).toFixed(2) + "MB",
        duration: this.actualduration, //this.formatDuration(duration) + "m",
        videoFile: res.data.data,
        allowDownload: true,
        pictureBackground: "",
      });
    },

    getVideoDuration(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const media = new Audio(reader.result);
          media.onloadedmetadata = () => resolve(media.duration);
        };
        reader.readAsDataURL(file);
        reader.onerror = (error) => reject(error);
      });
    },
    async checkAssignmentDelete(item) {
      let self = this;
      self.deleteassignmentcourse = true;
      self.editData = item;
    },
    async deleteData() {
      const res = await this.$axios.post(
        `${this.web_url}Assignment/RemoveAssignment?ID=` + this.editData.id
      );
      this.message = res.data.message;
      this.successDialog = true;
      this.deleteassignmentcourse = false;
      this.getAllAssignmentCourseData();
    },
    editassignmentcourse() {
      this.editassignmentcoursedialog = true;
    },
    editformatDate() {
      const [year, month, day] = this.editstartdate.split("-");
      return `${day}/${month}/${year}`;
    },
    editformatendDate() {
      const [year, month, day] = this.editenddate.split("-");
      return `${day}/${month}/${year}`;
    },
    formatDate() {
      const [year, month, day] =
        this.$store.state.assignmentUser.startdate.split("-");
      return `${day}/${month}/${year}`;
    },
    formatEndDate() {
      const [year, month, day] =
        this.$store.state.assignmentUser.enddate.split("-");
      return `${day}/${month}/${year}`;
    },
    async getAllAssignmentCourseData() {
      let self = this;
      const res = await self.$axios.get(
        `${self.web_url}Assignment/GetAllAssignment?CompanyID=` +
          localStorage.getItem("companyID")
      );
      self.items = res.data.data.map((v, i) => ({
        ...v,
        no: i + 1,
        startDateTime: moment(v.startDateTime).local().format("DD/MM/YYYY"),
        endDateTime: moment(v.endDateTime).local().format("DD/MM/YYYY"),
      }));
    },
    async saveAssignmentCourse() {
      const data = {
        id: 0,
        courseName: this.$store.state.assignmentUser.assignmentCourseName,
        startDateTime: this.$store.state.assignmentUser.startdate,
        endDateTime: this.$store.state.assignmentUser.enddate,
        mustWatch: this.$store.state.assignmentUser.mushwatchOrder,
        prohibit: this.$store.state.assignmentUser.prohiForward,
        allUser: this.$store.state.assignmentUser.allUserList,
        userIDList: [parseInt(this.selecteduserList[0].userID)],
        videoList: this.$store.state.assignmentUser.pcVideoData,
        loginUser: parseInt(localStorage.getItem("UserID")),
        companyID: localStorage.getItem("companyID"),
      };
      const res = await this.$axios.post(
        `${this.web_url}Assignment/AddOrUpdateAssignment`,
        data
      );
      if (res.data.status == 0) {
        this.addsuccessDialog = true;
        this.cancelAssignmentCourse();
        this.getAllAssignmentCourseData();
        this.$store.commit("closeAssignmentDialog");
        this.$store.commit("changeLibraryAction");
      }
    },
    async updateBanner() {
      if (this.$refs.form.validate() && this.file) {
        const base64Res = await this.$axios.post(
          `${this.web_url}File/UploadFileStringBase`,
          {
            base64: this.file,
            fileName: this.fileName,
          }
        );
        const data = {
          id: this.bannerID,
          title: this.bannerTitle,
          description: this.description,
          startDate: this.editstartdate,
          startTime: this.editformatDate() + " " + this.editstarttime + ":00",
          endDate: this.editenddate,
          endTime: this.editformatendDate() + " " + this.editendtime + ":00",
          buttonName: this.buttonName,
          pic: base64Res.data,
          linkURL: this.linkUrl,
          userID: parseInt(localStorage.getItem("UserID")),
          companyID: localStorage.getItem("companyID"),
        };
        const res = await this.$axios.post(
          `${this.web_url}Banner/AddOrUpdateBanner`,
          data
        );
        if (res.data.status == 0) {
          this.editsuccessDialog = true;
          this.cancelAssignmentCourse();
          this.getAllAssignmentCourseData();
        }
      } else {
        this.fileError = true;
      }
    },
    allowedDates: (val) => new Date(val),
    startDateDis(selecteddate) {
      this.calcRes(selecteddate);
      this.frommenu = false;
      this.allowedDatesEnd.todate = selecteddate;
      if (this.todate < this.allowedDatesEnd.todate) {
        this.todate = this.allowedDatesEnd.todate;
      }
    },
    allowedDatesEnd(val) {
      return val >= this.endDate;
    },
    calcRes(e) {
      this.endDate = e;
    },
    // Edit
    startallowedDates: (val) =>
      new Date(val).getTime() + 86400000 >= Date.now(),
    endallowedDates(val) {
      return val >= this.editenddate;
    },
    editcalcRes(e) {
      this.editenddate = e;
    },
    editstartDateDis(selecteddate) {
      this.editcalcRes(selecteddate);
      this.editstartdatemenu = false;
      this.endallowedDates.editstartdate = selecteddate;
      if (this.editstartdate < this.endallowedDates.editstartdate) {
        this.editstartdate = this.endallowedDates.editstartdate;
      }
    },
    //Add
    newallowedDates: (val) => new Date(val).getTime() + 86400000 >= Date.now(),
    newstartDateDis(selecteddate) {
      this.newcalcRes(selecteddate);
      this.startdatemenu = false;
      this.newendallowedDates.$store.state.assignmentUser.startdate =
        selecteddate;
      if (
        this.$store.state.assignmentUser.startdate <
        this.newendallowedDates.$store.state.assignmentUser.startdate
      ) {
        this.$store.state.assignmentUser.startdate =
          this.newendallowedDates.$store.state.assignmentUser.startdate;
      }
    },
    newendallowedDates(val) {
      return val >= this.$store.state.assignmentUser.enddate;
    },
    newcalcRes(e) {
      this.$store.state.assignmentUser.enddate = e;
    },
    addBanner() {
      this.newassignmentDialog = true;
    },
    cancelAssignmentCourse() {
      this.$refs.form.resetValidation();
      this.openAddDialog = false;
      this.editassignmentcoursedialog = false;
      this.starttime = "";
      this.$store.state.assignmentUser.assignmentCourseName = "";
      this.$store.state.assignmentUser.startdate = new Date()
        .toISOString()
        .substr(0, 10);
      this.endtime = "";
      this.$store.state.assignmentUser.mushwatchOrder = false;
      this.$store.state.assignmentUser.prohiForward = false;
      this.$store.state.assignmentUser.allUserList = false;
      this.$store.commit("saveSelecteduserList");
      this.$store.commit("removeSelectedvideoList");
      this.$store.commit("changeLibraryAction");
      this.$store.state.assignmentUser.enddate = new Date()
        .toISOString()
        .substr(0, 10);
      this.file = null;
      this.fileName = null;
      this.fileUrl = "";
      this.description = null;
      this.buttonName = null;
      this.linkUrl = null;
      this.editbannerdialog = false;
    },
  },
};
</script>
<style scoped>
::v-deep .theme--light.v-label {
  color: #47484b !important;
}
.assign-table {
  table-layout: fixed;
}
::v-deep .assingnment-course tr:nth-child(even) {
  background: #e0e0e0;
}
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .v-text-field__slot {
  font-size: 20px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 20px !important;
  color: #47484b !important;
  opacity: 1;
  text-align: center;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 20px !important;
  color: #47484b !important;
  opacity: 1;
  text-align: center;
}
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .to-date .v-input__slot {
  width: 155px !important;
}
.font-style {
  font-size: 20px;
  color: #4d4f5c;
}
::v-deep .v-input__slot {
  border-radius: 10px !important;
}
</style>
